import { MutableRefObject, useCallback, useRef } from 'react';
import { UnregisterCallback } from 'history';
import { Container } from 'typedi';

import { History } from 'store';

export const useBackButtonBlocking = (visible, disableClose, hide): [() => void, () => void] => {
  const unblock: MutableRefObject<UnregisterCallback> = useRef(null);

  const handleBack = useCallback((location, action: string): string | false | void => {
    if (action === 'POP' && visible && !disableClose) {
      hide();
      return false;
    }
  }, [hide, visible, disableClose]);

  const registerBackButtonPrompt = useCallback(() => {
    unblock.current = Container.get(History).block(handleBack);
  }, []);

  const unregisterBackButtonPrompt = useCallback(() => {
    if (unblock.current) {
      unblock.current();
      unblock.current = null;
    }
  }, []);

  return [registerBackButtonPrompt, unregisterBackButtonPrompt];
};
